<template>


    <nav class="header-design navbar navbar-expand-sm navbar-light bg-ligh">
  <div class="container">

    <a class="navbar-brand logo" href="javascript:void(0)">
      
      <img class="logo-design" src="assets/images/EMI-LOGO-1-1536x630.png" style="height: 70px;">

    </a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar" style="background-color: #fbcf56;">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="mynavbar">
      <ul class="navbar-nav mx-auto">

        <li class="nav-item">
          <router-link class="nav-link" to="/">Home</router-link>
        </li>

        <li class="nav-item">
          <router-link class="nav-link" to="/">Flyer Design</router-link>
        </li> 

        <li class="nav-item">
          <router-link class="nav-link" to="/signature-home">Logo Design</router-link>
        </li> 

         <li class="nav-item">
          <router-link class="nav-link" to="/web-design">Web Design</router-link>
        </li> 


        <li class="nav-item">
          <router-link class="nav-link" to="/terms">Terms & Conditions</router-link>
        </li> 
        
        <li class="nav-item">
          <a href="https://www.emiinfluencersapp.com/" target="_blank" class="nav-link" to="/">Main Website</a>
        </li>  

      </ul>

      <form class="d-flex">

        <a href="mailto:info@emiinfluencers.com" class="btn custom-btn" style="margin-right:10px;">Get Support</a>

       
      </form>
    </div>
  </div>
</nav>



</template>

<script>

export default {
    name : 'Header'
}

</script>