<template>

<Header />
<section style="background-color: #391847;">
    
    <div class="container">

        <div class="row">

            <div class="col-md-6" style="padding-top: 20px; padding-bottom: 20px; text-align: left; padding-top: 100px;">
                <h1 style="color:#fbcf56;">How should your flyer look and feel?</h1>
                <p style="color:lightgrey;">We'll tailer the font, icons and colors to match your style</p>

                <div>
                
                <button  :class="{btnstyle : show_error, 'feel-border' : feels.includes('Dynamic') }" @click="get_feel('Dynamic')" class="btn feel-btn text-danger">Dynamic</button>

                <button :class="{btnstyle : show_error, 'feel-border' : feels.includes('Creative')}" @click="get_feel('Creative')" class="btn feel-btn text-warning">Creative</button>

                <button :class="{btnstyle : show_error, 'feel-border' : feels.includes('Timeless')}" @click="get_feel('Timeless')" class="btn feel-btn text-success">Timeless</button>

                <button :class="{btnstyle : show_error, 'feel-border' : feels.includes('Modern')}" @click="get_feel('Modern')" class="btn feel-btn text-primary">Modern</button>

                <button :class="{btnstyle : show_error, 'feel-border' : feels.includes('Fun')}" @click="get_feel('Fun')" class="btn feel-btn text-info">Fun</button>

                <button :class="{btnstyle : show_error, 'feel-border' : feels.includes('Simple')}" @click="get_feel('Simple')" class="btn feel-btn text-secondary">Simple</button>

                <button :class="{btnstyle : show_error, 'feel-border' : feels.includes('Dark')}" @click="get_feel('Dark')" class="btn feel-btn text-white">Dark</button>

                <button :class="{btnstyle : show_error, 'feel-border' : feels.includes('Light')}" @click="get_feel('Light')" class="btn feel-btn text-info">Light</button>

                <button :class="{btnstyle : show_error, 'feel-border' : feels.includes('Excitement')}" @click="get_feel('Excitement')" class="btn feel-btn text-danger">Excitement</button>

                <div style="padding-top: 20px;">
                    <router-link to="/purpose" style="margin-right:10px;" class="btn custom-btn">Back</router-link>
                        <button class="btn custom-btn" @click="next_stage()">Next</button>
                </div>

               

            </div>

            </div>

 

            <div class="col-md-5" style="padding-top: 70px;">
        <img src="assets/images/flyer.png" style="width: 100%;" class="p-3 mb-5 animate__animated animate__zoomIn">

    </div>

        </div>
        
    </div>
    </section>
    <Footer />
</template>


<script>
import Header from './layouts/Header'
import Footer from './layouts/Footer'

export default {
    components : {Header,Footer},
    created (){},

    data () {
        return {
            purpose : '',
            feels : [],
            show_error : false
        }
    },

    methods : {

        get_feel(feel){
            if(this.feels.includes(feel)){
                this.feels.pop(feel)
            }else{
            this.feels = this.feels.concat(feel)
            localStorage.setItem('feels',this.feels);
            this.show_error=false    
            }
            
        },
        
        next_stage(){
            if(this.feels.length===0){
                this.show_error=true
                return
            }else{
              this.$router.push('/images')  
            }
        }
    }


}

</script>


<style scoped>

.feel-btn{
    border: solid 2px #fbcf56;
    border-radius: 20px;
    margin-top: 10px;
    margin-right: 10px;
    color: #fbcf56;
}

.feel-btn:hover{
    border: solid 2px lightgrey;   
}

.btnstyle{
    border: solid 2px red;
}

.feel-border{
    border: solid 2px #800;
}

</style>