<template>

    <Header />
     
    <section style="padding-top: 30px;">
        <div class="container">
            <h3>Logo Creation Terms & Conditions - Eminent Media Inc
</h3>

<p>Welcome to Eminent Media Inc's Logo Creation service. By engaging with our logo design services, you acknowledge and agree to the following terms and conditions</p>



<h4 style="color: #391847;">Initial Consultation:</h4>
<p>
The logo creation process commences after a thorough initial consultation where the client provides comprehensive information about their brand, vision, and design preferences.
</p>

<p>
    Eminent Media Inc will utilize this information to develop initial logo concepts that align with the client's vision.
</p>

<h4 style="color: #391847;">Design Review and Revisions:</h4>
<p>
Initial logo concepts will be presented for client review and feedback.
</p>

<p>
    Clients are entitled to a specified number of revisions, as outlined in the agreed-upon proposal
</p>

<p>
    Subsequent revisions beyond the agreed limit may be subject to additional charges.
</p>

<h4 style="color: #391847;">Additional Revisions</h4>
<p>
Any revisions beyond the initial three or the extra redesign, if applicable, will incur additional charges.
</p>

<h4 style="color: #391847;">Ownership and Usage Rights:</h4>
<p>
Upon receipt of full payment, clients obtain exclusive ownership rights to the final approved logo design.
</p>
<p>
    Eminent Media Inc retains the right to feature the completed work in our portfolio, marketing materials, and promotional channels, unless otherwise stipulated by the client.
</p>


<h4 style="color: #391847;">Requesting Revisions</h4>
<p>
All revision requests must be made within the agreed-upon timeframe. It is crucial for clients to provide detailed and specific feedback during revision requests to ensure we can meet your expectations effectively.
</p>



<h4 style="color: #391847;">Payment Terms:</h4>
<p>
Payment details, including the total cost and any required deposits, will be clearly outlined in the proposal or invoice.
</p>
<p>
    A non-refundable deposit may be necessary to initiate the logo design process, with the remaining balance due upon completion of the project.
</p>



<h4 style="color: #391847;">Confidentiality and Non-Disclosure:</h4>
<p>
Eminent Media Inc values the confidentiality of client information and ensures that all details shared during the logo design process remain confidential.
</p>
<p>
    Eminent Media Inc will not disclose any proprietary or sensitive information without explicit consent from the client.
</p>



<h4 style="color: #391847;">Project Timeline:</h4>
<p>
    A tentative project timeline will be provided, outlining key milestones and deadlines.
</p>
<p>
    Delays attributable to unforeseen circumstances will be communicated promptly, and best efforts will be made to adhere to the agreed-upon schedule.
</p>


<h4 style="color: #391847;">Termination of Services:</h4>
<p>
    Either party has the right to terminate the logo creation services if the other party breaches a material term of this agreement.
</p>
<p>
    In the event of termination, the client will be billed for services rendered up to that point.
</p>





<h4 style="color: #391847;">Governing Law:</h4>
<p>
    This agreement is governed by the laws of Canada, and any disputes will be resolved in accordance with these laws.
</p>









<p style="font-weight: 700;">
By proceeding with our logo creation services, you affirm that you have read, understood, and agreed to these terms and conditions. If you have any questions or concerns, please contact us for clarification before initiating the project. Eminent Media Inc looks forward to collaborating with you to create a distinctive and impactful logo for your brand.


</p>

        </div>
    </section>
    
    <Footer />
</template>


<script>
import Header from './layouts/Header'
import Footer from './layouts/Footer'

export default {
    components : {Header,Footer},
    
        data () {
            return {}
        },
    
        methods : {
    
        },
    
        created (){},
        
    }
    
    </script>
    
    