<template>


<section style="background-color:#391847; padding-bottom: 100px;" :class=" { 'blur' : show_modal } ">
    <Header />

    <div class="row">

        <div class="col-md-2"></div>


        <div class="col-md-8" style="padding-top:0px; text-align: center;">
            
           <div class="p-3 m-3 animate__animated animate__zoomIn">
               
               <h6 style="color:lightgrey;">Thank you for choosing us for your website project</h6>
               <h1 class="color-1"><strong>Your input is valuable in creating a website that meets your needs and goals</strong></h1>
               <h5 style="color:lightgrey;">
                   Please take a few moments to fill out this questionnaire
               </h5>


               <div>
                   
                   <button class="btn custom-btn" @click="start()">Get Started</button>
               </div>

        

           </div> 

        </div>

         <div class="col-md-2"></div>

        
         <div class="col-md-1"></div>
        <div class="col-md-10" style="text-align:center;"> 

            <h6 style="color:grey;">Our Designs</h6>

<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="2000" style="background-image: url('/assets/images/websites/1.jpg'); min-height: 500px; background-size: cover;">
    
    </div>

     <div class="carousel-item" data-bs-interval="2000" style="background-image: url('/assets/images/websites/2.jpg'); min-height: 500px; background-size: cover;">
    
    </div>

     <div class="carousel-item" data-bs-interval="2000" style="background-image: url('/assets/images/websites/3.jpg'); min-height: 500px; background-size: cover;">
    
    </div>

    <div class="carousel-item" data-bs-interval="2000" style="background-image: url('/assets/images/websites/4.jpg'); min-height: 500px; background-size: cover;">
    
    </div>
    
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
        </div>

    <div class="col-md-1"></div>

 

    </div>

    
</section>

<Footer />







<div class="my-modal animate__animated animate__zoomIn" style="padding-top:80px;" v-if="show_modal">
    
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
           
           <div style="background-color:#fff; text-align:left;" class="shadow-lg rounded p-3">
            
            <form @submit.prevent="next_step()" enctype="multipart/form-data">
                
                <h6 class="color"> <i class="bi bi-1-circle"></i> About your business</h6>

                <div class="row">
                    <div class="col-md-6 mt-2">
                        <label>What is the name of your business</label>
                        <input type="text" class="form-control input-design" placeholder="Enter business name" v-model="business_name">
                    </div>

                    <div class="col-md-6 mt-2">
                        <label>Can you provide a brief overview of your business</label>
                        <input type="text" class="form-control input-design" placeholder="Enter business description" v-model="overview">
                    </div>

                    <div class="col-md-6 mt-2">
                        <label>What is your target audience</label>
                        <input type="text" class="form-control input-design" placeholder="Enter target audience" v-model="audiance">
                    </div>

                    <div class="col-md-6 mt-2">
                        <label>Do you have any existing branding guidelines</label>
                        <input type="text" class="form-control input-design" placeholder="e.g.logo, color scheme, fonts, etc" v-model="brand">
                    </div>

                    <div class="col-md-12 mt-2 mb-2 border-bottom">
                        <h6 class="color"> <i class="bi bi-2-circle"></i> Website goals</h6>
                    </div>

                    <div class="col-md-12 mt-2">
                        <label>What are the main goals you want to achieve with your website</label>
                        <input type="text" class="form-control input-design" placeholder="e.g., increase online sales, generate leads, provide information, etc." v-model="goals">
                    </div>

                    <div class="col-md-6 mt-2">
                        <label>Are there any specific features or functionality you want to include on the website</label>
                        <input type="text" class="form-control input-design" placeholder="e.g., e-commerce, blog, contact forms, etc." v-model="features">
                    </div>

                    <div class="col-md-6 mt-2">
                        <label>Do you have any specific examples of websites you like?</label>
                        <input type="text" class="form-control input-design" placeholder="If yes,please specify" v-model="examples">
                    </div>

                    <div class="col-md-12 mt-3 mb-2 border-bottom">
                        <h6 class="color"> <i class="bi bi-3-circle"></i>Website content</h6>
                    </div>


                    <div class="col-md-6 mt-2">
                        <label>Do you have any texts? (Upload)</label>
                        <input type="file" class="form-control input-design" @change="get_text">
                    </div>

                    <div class="col-md-6 mt-2">
                        <label>Do you have any images? (Upload)</label>
                        <input type="file" class="form-control input-design" @change="get_images">
                    </div>

                    <div class="col-md-12 mt-3">
                        <button class="btn custom-btn-3" type="button" @click="show_modal=false" style="margin-right:10px;">Close</button>
                        <button class="btn custom-btn-3">Next</button>
                    </div>
                    
                </div>

            </form>   
            

           </div>

        </div>
        <div class="col-md-2"></div>
    </div>

</div>







</template>


<script>
import Header from './layouts/Header'
import Footer from './layouts/Footer'

export default {
    name : 'web design',
    components : {Header,Footer},
    data(){
        return{
            show_modal : false,
            business_name : '',
            overview : '',
            audiance : '',
            brand : '',
            goals : '',
            features : '',
            examples : '',
            text : '',
            images : ''
        }
    },
    methods : {

        get_text(e){
           this.text = e.target.files[0]
           this.$store.state.website_text = this.text
        },

        get_images(e){
            this.images = e.target.files[0]
            this.$store.state.website_images = this.images
        },

        start(){
            this.show_modal = true
        },
        next_step(){
            if(!this.business_name){
                alert('Business name is required')
                return
            }

             if(!this.audiance){
                alert('Audience is required')
                return
            }

            localStorage.setItem('business_name',this.business_name)
            localStorage.setItem('overview',this.overview)
            localStorage.setItem('audiance',this.audiance)
            localStorage.setItem('brand',this.brand)
            localStorage.setItem('goals',this.goals)
            localStorage.setItem('features',this.features)
            localStorage.setItem('examples',this.examples)
            localStorage.setItem('images',this.images)
            localStorage.setItem('text',this.text)

            this.$router.push('/website-technical-details')
        }
    },

    created (){
        window.scrollTo(0,0)
    }
    
}

</script>
