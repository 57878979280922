<template>
<section class="blur">
<Header />

<section style="background-color:#391847; padding-top:200px; padding-bottom: 200px;">



    <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">


<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="2000" style="background-image: url('/assets/images/websites/1.jpg'); min-height: 500px; background-size: cover;">
    
    </div>

     <div class="carousel-item" data-bs-interval="2000" style="background-image: url('/assets/images/websites/2.jpg'); min-height: 500px; background-size: cover;">
    
    </div>

     <div class="carousel-item" data-bs-interval="2000" style="background-image: url('/assets/images/websites/3.jpg'); min-height: 500px; background-size: cover;">
    
    </div>

    <div class="carousel-item" data-bs-interval="2000" style="background-image: url('/assets/images/websites/4.jpg'); min-height: 500px; background-size: cover;">
    
    </div>
    
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
        
    </div>
    
</div>

    


</section>




<Footer />
</section>



<section>

    <div class="my-modal animate__animated animate__zoomIn"  style="padding-top: 100px; margin-bottom:100px;">


        <div class="row">

            <div class="col-md-2"></div>

            <div class="col-md-8">

                <form @submit.prevent="get_website_order()" class="rounded shadow p-3" style="background-color:#fff;" enctype="multipart/form-data">
                
                <h6 class="color"> <i class="bi bi-1-circle"></i> Technical details</h6>

                <div class="row">
                    <div class="col-md-12 mt-2">
                        <label>Do you have a domain name and hosting <br />already?</label>
                        <input type="text" class="form-control input-design" placeholder="If not, do you need assistance with purchasing these?" v-model="hosting">
                    </div>

                    <div class="col-md-6 mt-2">
                        <label>Will the website need to be optimized for search engines (SEO)?</label>
                        <input type="text" class="form-control input-design" v-model="seo">
                    </div>

                    <div class="col-md-6 mt-2">
                        <label>Do you require any specific <br />integrations</label>
                        <input type="text" class="form-control input-design" placeholder="(e.g., CRM, email marketing, social media, etc.)?" v-model="integrations">
                    </div>

                    

                    <div class="col-md-12 mt-3 mb-2 border-bottom">
                        <h6 class="color"> <i class="bi bi-2-circle"></i> Timeline and budget</h6>
                    </div>

                    <div class="col-md-12 mt-2">
                        <label>What is your preferred timeline for completing the website project?</label>
                        <input type="text" class="form-control input-design" v-model="timeline" placeholder="eg days or weeks"> 
                    </div>

                    <div class="col-md-12 mt-2">
                        <label>Additional comments</label>
                        <input type="text" class="form-control input-design" placeholder="Is there anything else you would like to add that hasn't been covered in this questionnaire?" v-model="comments">
                    </div>

                    

                    <div class="col-md-12 mt-3 mb-2 border-bottom">
                        <h6 class="color"> <i class="bi bi-3-circle"></i> Contact information</h6>
                    </div>


                    <div class="col-md-6 mt-2">
                        <label>First name</label>
                        <input type="text" class="form-control input-design" v-model="first_name" placeholder="Enter your first name">
                    </div>

                    <div class="col-md-6 mt-2">
                        <label>Last name</label>
                        <input type="text" class="form-control input-design" v-model="last_name" placeholder="Enter your last name">
                    </div>

                    <div class="col-md-6 mt-2">
                        <label>Email</label>
                        <input type="email" class="form-control input-design" v-model="email" placeholder="Enter your email address">
                    </div>

                    <div class="col-md-6 mt-2">
                        <label>Phone</label>
                        <input type="text" class="form-control input-design" v-model="phone" placeholder="Enter your phone number">
                    </div>

                    <div class="col-md-12 mt-3">
                        <router-link to="/web-design" class="btn custom-btn-3" style="margin-right: 10px;">Back</router-link>
                        <button class="btn custom-btn-3" :disabled="disabled">{{btn_text}}</button>
                    </div>
                    
                </div>

            </form>  
                
            </div>

            <div class="col-md-2"></div>
            
        </div>

         
        
    </div>
    

    
</section>




</template>


<script>
import Header from './layouts/Header'
import Footer from './layouts/Footer'
import axios from 'axios'

export default {
    name : 'web design',
    components : {Header,Footer},

    data(){
        return{
            hosting : '',
            seo : '',
            integrations : '',
            timeline : '',
            comments : '',
            first_name : '',
            last_name : '',
            phone : '',
            email : '',
            disabled : false,
            btn_text : 'Submit'
        }
    },


    methods : {
        async get_website_order(){

            if(!this.first_name){
                alert('First name is required ')
                return
            }

             if(!this.last_name){
                alert('Last name is required ')
                return
            }

             if(!this.email){
                alert('Email is required ')
                return
            }

             if(!this.phone){
                alert('Phone is required ')
                return
            }

            this.btn_text = 'Please wait...'
            this.disabled = true

            localStorage.setItem('hosting',this.hosting)
            localStorage.setItem('seo',this.seo)
            localStorage.setItem('timeline',this.timeline)
            localStorage.setItem('comments',this.comments)
            localStorage.setItem('first_name',this.first_name)
            localStorage.setItem('last_name',this.last_name)
            localStorage.setItem('phone',this.phone)
            localStorage.setItem('email',this.email)

             const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
            const res = await axios.post(this.$store.state.api_url+'api/submit-website-order',{
            business_name : localStorage.getItem('business_name'),
            overview : localStorage.getItem('overview'),
            audiance : localStorage.getItem('audiance'),
            goals :  localStorage.getItem('goals'),
            brand : localStorage.getItem('brand'),
            examples : localStorage.getItem('examples'),
            features : localStorage.getItem('features'),
            text : this.$store.state.website_text,
            images : this.$store.state.website_images,
            hosting : localStorage.getItem('hosting'),
            seo : localStorage.getItem('seo'),
            timeline : localStorage.getItem('timeline'),
            comments : localStorage.getItem('comments'),
            first_name : localStorage.getItem('first_name'),
            last_name : localStorage.getItem('last_name'),
            email : localStorage.getItem('email'),
            phone : localStorage.getItem('phone'),

            },config).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.btn_text = 'Submit'
            this.disabled = false

            this.$router.push('/success')
            
        }
    },

    created (){
        this.business_name = localStorage.getItem('business_name')
        window.scrollTo(0,0)
    }
    
}

</script>
