<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {},
  created (){
    window.scrollTo('0','0')
  }
}
</script>

