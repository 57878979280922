<template>

    <div :class=" { 'blur' : !show_page } ">


    <Header />

    <section style="background-color: #391847; padding-bottom: 100px;">

        <div class="container">

            <div class="row">

                <div class="col-md-12" style="text-align: center; padding-top: 50px;">
                     <label class="text-white message">Before you proceed you might want to read our logo design <router-link to="/logo-terms">Terms & Conditions</router-link></label>
                    <h4 style="color: lightgrey">Order for logo</h4>
                    <h1 style="color: #fbcf56;">What sample of logo do you prefer?</h1>   
                </div>

                <div class="col-md-1"></div>


                <div class="col-md-10">

                    
                    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
 
  <div class="carousel-inner">

    <div class="carousel-item active" style="text-align: center;">
      <button class="btn btn-sm" style="position: absolute; background-color: #fbcf56;"> <i class="bi bi-check-circle"></i>Premium</button>
      <button class="btn signature-btn"><img src="/assets/images/signatures/Signature-1.jpg" class="signature" :class="{errorstyle:show_error, 'image-border' : images.includes('Signature-1.jpg') }"  @click="get_image('Signature-1.jpg')"></button>

      <button class="btn"><img src="/assets/images/signatures/Signature-2.jpg" class="signature" :class="{errorstyle:show_error, 'image-border' : images.includes('Signature-2.jpg') }"  @click="get_image('Signature-2.jpg')"></button>

      <button class="btn"><img src="/assets/images/signatures/Signature-3.jpg" class="signature" :class="{errorstyle:show_error, 'image-border' : images.includes('Signature-3.jpg') }"  @click="get_image('Signature-3.jpg')"></button>
   
    </div>


    <div class="carousel-item" style="text-align: center;">
      <button class="btn btn-sm" style="position: absolute; background-color: #fbcf56;"><i class="bi bi-check-circle"></i> Premium</button>
      <button class="btn"><img src="/assets/images/signatures/Signature-4.jpg" class="signature" :class="{errorstyle:show_error, 'image-border' : images.includes('Signature-4.jpg') }"  @click="get_image('Signature-4.jpg')"></button>

      <button class="btn"><img src="/assets/images/signatures/Signature-5.jpg" class="signature" :class="{errorstyle:show_error, 'image-border' : images.includes('Signature-5.jpg') }"  @click="get_image('Signature-5.jpg')"></button>

      <button class="btn"><img src="/assets/images/signatures/Signature.jpg" class="signature" :class="{errorstyle:show_error, 'image-border' : images.includes('Signature.jpg') }"  @click="get_image('Signature.jpg')"></button>
   
    </div>


   


  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>


<div style="text-align: center; padding-top: 20px; ">
    <button class="btn custom-btn" @click="next_stage()">Proceed <i class="bi bi-arrow-right"></i></button>
</div>


                </div>

                <div class="col-md-1"></div>

            </div>

        </div>

    </section>


  
<Footer />


        
    </div>






<div class="my-modal animate__animated animate__zoomIn" style="padding-top:100px;" v-if="!show_page">
    
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
           
           <div style="background-color:#fff; text-align:center;" class="shadow-lg rounded p-3">
               
               <h6>Which type of logo do you need?</h6>


               <div>
                   <button class="btn custom-btn-3" style="margin-right:10px;" @click="get_logo_type('Signature logo')"> <i class="bi bi-1-circle"></i> Signature logo</button>
                   <button class="btn custom-btn-3" @click="get_logo_type('Logo')"> <i class="bi bi-2-circle"></i> Just a logo</button>
               </div>

               <div style="padding-top:20px;">
                   <router-link to="/" class="btn color"> <i class="bi bi-arrow-left"></i> Go back</router-link>
               </div>

           </div>

        </div>
        <div class="col-md-4"></div>
    </div>

</div>





</template>



<script>
import Header from './layouts/Header'
import Footer from './layouts/Footer'

export default {
    name : 'signaturehome',
    components : {Header,Footer},

    data(){
        return{
            images : [],
            logo_type : '',
            show_page : false
        }
    },

    methods : {

        get_logo_type(logo){
            this.logo_type = logo 
            localStorage.setItem('logo_type',logo)
            this.show_page = true
        },

         get_image(image){
            this.images = []
            if(this.images.includes(image)){
                this.images.pop(image)
            }else{
                        this.images = this.images.concat(image)
            localStorage.setItem('images',this.images)
            this.show_error=false    
            }
            
        },

      next_stage(){
        this.$router.push('/signature-details')
      }
    }

}

</script>


<style scoped>
    .image-border{
    border: solid 2px #800;
}
</style>


