<template>
<Header />

<section style="background-color: #391847; padding-bottom: 100px;" id="top">

    <div class="container">

<div class="row">
    <div class="col-md-6" style="padding-top: 100px; text-align: left;">
        <div style="padding-bottom: 30px;">
            <h2 style="color:lightgrey;"  class="animate__animated animate__pulse animate__delay-1s">Welcome</h2>
            <h1 style="color: #fbcf56;">Start designing your flyer</h1>
        </div>

        <form @submit.prevent="get_name()">
        <div>
  <input type="text" class="form-control input-design" placeholder="Enter your personal or company name" aria-label="Company name" aria-describedby="button-addon2" v-model="company_name">
</div>

<div style="padding-top: 20px;">
    <p style="color:lightgrey;">We do:</p>
    <button class="btn badges animate__animated animate__pulse animate__delay-1s"> <i class="bi bi-check"></i> | Timely designs</button>
    <button class="btn badges animate__animated animate__pulse animate__delay-2s"> <i class="bi bi-check"></i> | Unique designs</button>
    <button class="btn badges animate__animated animate__pulse animate__delay-3s"> <i class="bi bi-check"></i> | Creative designs</button>
    <button class="btn badges animate__animated animate__pulse animate__delay-4s"> <i class="bi bi-check"></i> | Agile designs</button>
</div>

<div style="padding-top:20px;">
    <button class="btn custom-btn">Next Step</button>
</div>

</form>



    </div>

    <div class="col-md-6" style="padding-top: 70px;">

          <video style="width: 100%; border:solid 4px #000; border-radius:0px;"  autoplay loop muted class="animate__animated animate__zoomIn">
  <source src="/assets/videos/video_preview_h264 (1).mp4" type="video/mp4">

Your browser does not support the video tag.
</video>

        <!--
        <img src="assets/images/flyer.png" style="width: 100%;" class="p-3 mb-5 animate__animated animate__zoomIn">-->

    </div>

</div>

</div>
</section>




<Footer />
</template>


<script>
import Header from './layouts/Header'
import Footer from './layouts/Footer'

export default {
    name : 'home',
    components : {Header,Footer},

    data () {
        return {
            company_name : ''
        }
    },

    methods : {
        get_name(){
            if(!this.company_name){
                alert('Company name is required')
                return 
            }
            localStorage.setItem('company_name',this.company_name)
            this.$router.push('/purpose')
        }
    },

    created (){},
    
}

</script>


<style>
.badges{
    border: solid 2px #fbcf56;
    color: #fbcf56;
    margin-right: 10px;
    margin-top: 5px;
}

.badges:hover{
    border: solid 2px #fbcf56;
    color: #fbcf56;
    margin-right: 10px;
}
</style>
