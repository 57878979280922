<template>

<div :class=" { blur : show_payment_modal } ">



     <Header />
     <section style="background-color: #391847; padding-bottom:100px;">
    <div class="container">

        <div class="row">
           

            <div class="col-md-6" style="padding-top: 100px; padding-bottom: 20px; text-align: left;">
                <h1 style="color: #fbcf56;" class="animate__animated animate__pulse animate__delay-1s">Whats your flyer for?</h1>
                <p style="color:lightgray;">Add your business or industry to get the best options for you</p>


            <div>
                <form @submit.prevent="get_purpose()" enctype="multipart/form-data">
                    <select v-model="purpose" class="form-control input-design" @change="change_image()">
                        <option disabled value="" selected >Please select one</option>
                        <option>Arts,Entertainment and Recreation</option>
                        <option>Real Estate Agency</option>
                        <option>Fitness Center</option>
                        <option>Restaurant</option>
                        <option>Technology Company</option>
                        <option>Healthcare Clinic</option>
                        <option>Event Planning Company</option>
                        <option>Travel Agency</option>
                        <option>Education Center</option>
                        <option>Retail Store</option>
                        <option>Non Profit organization</option>
                        <option>Automotive Repair Shop</option>
                        <option>Freelance Photography Services</option>
                        <option>Financial Consulting Firm</option>
                        <option>Wedding Planning Services</option>
                        <option>Pet Grooming Salon</option>
                    </select>
                    
                    <div style="padding-top: 20px;">
                        <p style="color:lightgray;">Whats the intended population of your event?</p>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                        
                        <select v-model="audiance" class="form-control input-design">
                        <option disabled value="">Please select one</option>
                        <option>Silent Generation: Ages 79 - 99</option>
                        <option>Baby Boomer Generation: Ages 60 - 78</option>
                        <option>Generation X (Baby Bust): Ages 45 - 59</option>
                        <option>Xennials: Ages 39 - 49</option>
                        <option>Millennials (Generation Y): Ages 30 - 44</option>
                        <option>iGen / Gen Z: Ages 12 - 29</option>
                        <option>Gen Alpha: Ages 1 - 11</option>
                        
                    </select>

                        </div>
                    </div>

                    <div style="padding-top: 20px;">
                        <router-link to="/" style="margin-right:10px;" class="btn custom-btn" >Back</router-link>
                        <button type="submit" class="btn custom-btn">Next</button>
                    </div>
                </form>
            </div>

            <div class="moda" v-if="show_form">
                <div class="moda-content">
            <form @submit.prevent="event_details()">
                
                <div class="row">
                    <div class="col-md-8 col-10">
                        <h4>Specify the details of your flyer</h4>
                    </div>
                    <div class="col-md-4 col-2" style="text-align:right;">
                        <button class="btn text-danger" @click="show_form=false"> <i class="bi bi-x-circle"></i> </button>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-12">
                        <input type="text" class="form-control input-design" placeholder="Title of event" v-model="event_title">
                    </div>

                    <div class="col-md-3" style="padding-top: 10px;">
                        <label for="">Event start date</label>
                        <input type="date" class="form-control input-design" placeholder="Event date" v-model="event_date">
                    </div>

                    <div class="col-md-3" style="padding-top: 10px;">
                        <label for="">Event start time</label>
                        <input type="time" class="form-control input-design" placeholder="Event time" v-model="event_time">
                    </div>


                      <div class="col-md-3" style="padding-top: 10px;">
                        <label for="">Event end date</label>
                        <input type="date" class="form-control input-design" placeholder="Event date" v-model="event_end_date">
                    </div>

                    <div class="col-md-3" style="padding-top: 10px;">
                        <label for="">Event end time</label>
                        <input type="time" class="form-control input-design" placeholder="Event time" v-model="event_end_time">
                    </div>



                    <div class="col-md-6" style="padding-top: 10px;">
                        <input type="text" class="form-control input-design" placeholder="Event location" v-model="event_location">
                    </div>

                    <div class="col-md-6" style="padding-top: 10px;">
                        <input type="text" class="form-control input-design" placeholder="Event contacts" v-model="event_contacts">
                    </div>

                    <div class="col-md-6" style="padding-top: 10px;">
                        <label>Date you want flyer completed</label>
                        <input type="date" class="form-control input-design"  v-model="deadline">
                    </div>

                    <div class="col-md-6" style="padding-top: 10px;">
                        <label>What is the desired size & format of the flyer? </label>
                        <input type="text" class="form-control input-design" placeholder="E.g., Instagram size, A4, 4;5"  v-model="flyer_size">
                    </div>

                    <div class="col-md-6" style="padding-top: 10px;">
                        <label>Will you need a digital copy, print copy, or both? </label>
                        <input type="text" class="form-control input-design" placeholder="If yes specify"  v-model="flyer_type">
                    </div>

                    <div class="col-md-6" style="padding-top: 10px;">
                        <label>Do you have any brand guidelines we should follow?  </label>
                        <input type="text" class="form-control input-design"  placeholder="e.g., Colors, Layering,"  v-model="guidelines">
                    </div>

                    <div class="col-md-6" style="padding-top: 10px;">
                        <label>Do you have a specific theme or design in mind?  </label>
                        <input type="text" class="form-control input-design"  placeholder="e.g. color schemes, or specific styles"  v-model="theme">
                    </div>

                    <div class="col-md-6" style="padding-top: 10px;">
                        <label>Will you provide the images, or do we need to source them?  </label>
                        <input type="text" class="form-control input-design"  placeholder="If we’re Sourcing, please describe the image you’re going for"  v-model="image_sources">
                    </div>

                    <div class="col-md-6" style="padding-top: 10px;">
                        <label>Do you have any legal or copyright requirements we should be aware of?  </label>
                        <input type="text" class="form-control input-design"  placeholder="If yes, specify"  v-model="copyright">
                    </div>


                    <div class="col-md-6" style="padding-top: 10px;">
                        <label>Do you want us to include any QR codes or barcodes?  If yes, please attach  </label>
                        <input type="file" class="form-control input-design"  @change="qr_change">
                    </div>

                    <div class="col-md-6" style="padding-top: 10px;">
                        <label>Would you like the flyer to be single-sided or <br/>double-sided?  </label> <br/>
                        <input type="radio" v-model="sides" value="Single" checked> Single sided
                        <input type="radio" v-model="sides" value="Double"> Double sided
                    </div>

                    <div class="col-md-6" style="padding-top: 10px;">
                        <label> Do you have any competitors? If yes, could you provide examples of their marketing materials?  </label>
                        <input type="text" class="form-control input-design"   v-bind="competitors">
                    </div>


                    <div class="col-md-6" style="padding-top: 10px;">
                        <label>Would you like to include personal logos or images? <br/> Please share </label>
                        <input type="file" class="form-control input-design" @change="logo_change">
                    </div>

                    <div class="col-md-6" style="padding-top: 10px;">
                        <label>Do you have any samples? <br/> Please share </label>
                        <input type="file" class="form-control input-design" @change="sample_change">
                    </div>

                    <div class="col-md-12" style="padding-top: 10px;">
                        <label>Is there anything that we might have left out that may not be in the previous questions? </label>
                        <input type="text" class="form-control input-design"   v-model="comments">
                    </div>

                    

                    

                 

                    <div class="col-md-12" style="padding-top: 10px;">
                        <h4>Contact information</h4>
                    </div>

                    <div class="col-md-4" style="padding-top: 10px;">
                        <input type="text" class="form-control input-design"  v-model="name" placeholder="Your name">
                    </div>

                    <div class="col-md-4" style="padding-top: 10px;">
                        <input type="email" class="form-control input-design"  v-model="email" placeholder="Your email">
                    </div>

                    <div class="col-md-4" style="padding-top: 10px;">
                        <input type="text" class="form-control input-design"  v-model="phone" placeholder="Your phone number">
                    </div>

                    <div class="col-md-12" style="padding-top: 10px;">
                        <button @click="show_form=false" type="button" class="btn custom-btn-3" style="margin-right: 10px;"> <i class="bi bi-x-circle color"></i> Close</button>
                        <button class="btn custom-btn" :disabled="disabled"> {{text}} <i class="bi bi-arrow-right color"></i> </button>
                    </div>

                </div>
            </form>
            </div>
            </div>

            </div>

            <div class="col-md-5" style="padding-top: 70px;">
                <img :src='selected_image' style="width: 100%;" class="slider-image" :class='class' >

            </div>

        </div>
        
    </div>
</section>



    <Footer />

    


    
</div>



<!-- payment modal -->
<div class="my-modal m-2" v-if="show_payment_modal">
    
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6" style="padding-top:100px;">
            
            <div style="background-color:#fff;" class="shadow-lg rounded p-3">
                <h6><b>Pay now and have your flyer designed quicker</b></h6>

                <div style="padding-top:10px;">
                    <h5>How to pay via <b>e-transfer</b> </h5>
                </div>

                <div>
                    <p> <i class="bi bi-check color"></i> Pay 50CAD to <b>Eminentmediainc@gmail.com</b></p>
                     <p> <i class="bi bi-check color"></i> After paying upload screenshot of <b>payment receipt</b> here</p>
                </div>

                <div>
                    
                    <form @submit.prevent="submit_receipt()" enctype="multipart/form-data">
                        <input type="file" required class="form-control input-design" @change="get_receipt_file">
                        <div style="padding-top:20px;">
                            <div class="row">
                                <div class="col-md-6 col-6">
                                    <button class="btn custom-btn-3" :disabled="disabled2">{{text2}}</button>
                                </div>
                                <div class="col-md-6 col-6" style="text-align:right;">
                                    <a href="https://buy.stripe.com/9AQ28f0js83g2VG5kl" class="btn custom-btn">Pay online</a>
                                    <router-link to="/success" type="button" class="btn color"> <i class="bi bi-x-circle"></i> I will pay later</router-link>
                                </div>
                            </div>
                        </div>
                    </form>

                    

                </div>

            </div>

        </div>

         <div class="col-md-3"></div>
    </div>

</div>


</template>


<script>
import Header from './layouts/Header'
import Footer from './layouts/Footer'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from 'axios'

export default {
    components : {Header,Footer},
    created (){},

    data () {
        return {
            purpose : '',
            show_error : false,
            show_form : false,
            event_title : '',
            event_date : '',
            event_time : '',
            event_location : '',
            event_contacts : '',
            deadline  : '',
            selected_image : 'assets/images/flyers/new-image.jpg',
            audiance : '',
            name : '',
            email :'',
            phone : '',
            logos : '',
            qr_code : '',
            flyer_size : '',
            flyer_type : '',
            guidelines : '',
            theme : '',
            image_sources : '',
            copyright : '',
            sides : '',
            competitors : '',
            comments : '',
            sample : '',
            class : 'animate__animated animate__zoomIn',
            text : 'Submit',
            disabled : false,
            receipt : '',
            text2 : 'Submit receipt',
            disabled2 : false,
            show_payment_modal : false,
            event_end_time : '',
            event_end_date : ''

        }
    },



    methods : {

        async submit_receipt(){
            this.text2 = 'Please wait...'
            this.disabled2 = true
             const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
            const res =  await axios.post(this.$store.state.api_url+'api/submit-receipt',{
                receipt : this.receipt
            },config).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.text2 = 'Submit receipt'
            this.disabled2 = false
            this.$router.push('/success')

        },

         get_receipt_file(e){
            this.receipt = e.target.files[0]
           
            
        },

         async submit_details(){
            this.text = 'Please wait...'
            this.disabled = true
             //uploa a file
             const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
                //uploa a file
                //console.log(this.$store.state.sample)
            const res = await axios.post(this.$store.state.api_url+'api/submit-flyer-details',{
                company_name : localStorage.getItem('company_name'),
                purpose : localStorage.getItem('purpose'),
                audiance : localStorage.getItem('audiance'),
                company_name : localStorage.getItem('company_name'),
                event_title : localStorage.getItem('event_title'),
                event_time : localStorage.getItem('event_time'),
                event_location : localStorage.getItem('event_location'),
                event_contacts : localStorage.getItem('event_contacts'),
                deadline : localStorage.getItem('deadline'),
                name : localStorage.getItem('name'),
                email : localStorage.getItem('email'),
                phone : localStorage.getItem('phone'),
                event_date : localStorage.getItem('event_date'),
                flyer_size : localStorage.getItem('flyer_size'),
                flyer_type : localStorage.getItem('flyer_type'),
                guidelines : localStorage.getItem('guidelines'),
                theme : localStorage.getItem('theme'),
                image_sources : localStorage.getItem('image_sources'),
                copyright : localStorage.getItem('copyright'),
                sides : localStorage.getItem('sides'),
                competitors : localStorage.getItem('competitors'),
                logos : this.$store.state.logos,
                comments : localStorage.getItem('comments'),
                sample : this.$store.state.sample,
                qr_code : this.$store.state.qr_code, 

            },config).then(function(response){
                console.log(response.data)
            }).catch(function(error){
                console.log(error)
            })

            this.text = 'Order submitted!'
            this.disabled = false
            this.show_form = false
            this.show_payment_modal = true
           
        },

        logo_change(e){
            this.logos = e.target.files[0]
        },

        sample_change(e){
            this.sample = e.target.files[0]
        },

        qr_change(e){
            this.qr_code = e.target.files[0]
            
        },

        get_purpose (){
            if(!this.purpose){
                this.show_error=true
                 toast.error("Please select industry", {
        autoClose: 1000,
        theme : 'dark',
        position : 'top-center'
      });
                return
            }

            if(!this.audiance){
                this.show_error=true
                 toast.error("Please select intended population", {
        autoClose: 1000,
        theme : 'dark',
        position : 'top-center'
      });
                return
            }


            localStorage.setItem('purpose',this.purpose)
            this.show_error=false
            this.show_form = true
            
        },

        change_image(){

            if(this.purpose=='Arts,Entertainment and Recreation'){
             this.selected_image = 'assets/images/flyers/'+'Event (1).jpg'   
            }

             if(this.purpose=='Real Estate Agency'){
             this.selected_image = 'assets/images/flyers/'+'Real Estate (2).jpg'   
            }

             if(this.purpose=='Fitness Center'){
             this.selected_image = 'assets/images/flyers/'+'a (1).jpg'   
            }

              if(this.purpose=='Restaurant'){
             this.selected_image = 'assets/images/flyers/'+'Christmas Flyer_2.jpg'   
            }

            if(this.purpose=='Technology Company'){
             this.selected_image = 'assets/images/flyers/'+'Tech (2).jpg'   
            }

              if(this.purpose=='Healthcare Clinic'){
             this.selected_image = 'assets/images/flyers/'+'Fr Val Medical Care Flyer.jpg'   
            }

            if(this.purpose=='Event Planning Company'){
             this.selected_image = 'assets/images/flyers/'+'Event (3).jpg'   
            }


            if(this.purpose=='Travel Agency'){
             this.selected_image = 'assets/images/flyers/'+'NPA Canada Tour Flyer.jpg'   
            }

              if(this.purpose=='Education Center'){
             this.selected_image = 'assets/images/flyers/'+'After School Program Course Flyer.jpg'   
            }


              if(this.purpose=='Retail Store'){
             this.selected_image = 'assets/images/flyers/'+'Imag (1).jpg'   
            }


              if(this.purpose=='Non Profit organization'){
             this.selected_image = 'assets/images/flyers/'+'YNOT Potential Within Flyer_1-01.jpg'   
            }


              if(this.purpose=='Automotive Repair Shop'){
             this.selected_image = 'assets/images/flyers/'+'Automotive Repair Shop (1).jpg'   
            }

        if(this.purpose=='Automotive Repair Shop'){
             this.selected_image = 'assets/images/flyers/'+'Automotive Repair Shop (1).jpg'   
            }
      if(this.purpose=='Freelance Photography Services'){
             this.selected_image = 'assets/images/flyers/'+'EMI Creative Studios Hiring Flyer.jpg'   
            }

          if(this.purpose=='Financial Consulting Firm'){
             this.selected_image = 'assets/images/flyers/'+'Financial Consulting Firm (1).jpg'   
            }

              if(this.purpose=='Wedding Planning Services'){
             this.selected_image = 'assets/images/flyers/'+'Wedding Planner (3).jpg'   
            }

              if(this.purpose=='Pet Grooming Saloon'){
             this.selected_image = 'assets/images/flyers/'+'Pet Grooming Salon (3).jpg'   
            }
            
            
        },

    

        event_details(){
            
            if(!this.event_title){
                alert('Event title is required')
                return 0
            }

            if(!this.event_date){
                alert('Event date is required')
                return 0
            }

            if(!this.event_time){
                alert('Event time is required')
                return 0
            }

             if(!this.event_end_date){
                alert('Event end date is required')
                return 0
            }

             if(!this.event_end_time){
                alert('Event end time is required')
                return 0
            }

            if(!this.event_location){
                alert('Event location is required')
                return 0
            }

            if(!this.event_contacts){
                alert('Event contacts is required')
                return 0
            }

            if(!this.deadline){
                alert('Deadline is required')
                return 0
            }

            if(!this.name){
                alert('Name is required')
                return 0
            }

            if(!this.email){
                alert('Email is required')
                return 0
            }
            if(!this.phone){
                alert('Phone is required')
                return 0
            }


            localStorage.setItem('selected_image',this.selected_image)
            localStorage.setItem('event_title',this.event_title)
            localStorage.setItem('event_date',this.event_date)
            localStorage.setItem('event_time',this.event_time)
            localStorage.setItem('event_location',this.event_location)
            localStorage.setItem('event_contacts',this.event_contacts)
            localStorage.setItem('deadline',this.deadline)
            localStorage.setItem('name',this.name)
            localStorage.setItem('email',this.email)
            localStorage.setItem('phone',this.phone)

            localStorage.setItem('flyer_size',this.flyer_size)
            localStorage.setItem('flyer_type',this.flyer_type)
            localStorage.setItem('guidelines',this.guidelines)
            localStorage.setItem('theme',this.theme)
            localStorage.setItem('image_sources',this.image_sources)
            localStorage.setItem('copyright',this.copyright)
            localStorage.setItem('qr_code',this.qr_code)
            localStorage.setItem('sides',this.sides)
            localStorage.setItem('competitors',this.competitors)
            localStorage.setItem('logos',this.logos)
            localStorage.setItem('comments',this.comments)
            localStorage.setItem('sample',this.sample)

            this.$store.state.sample = this.sample
            this.$store.state.logos = this.logos
            this.$store.state.qr_code = this.qr_code

            localStorage.setItem('audiance',this.audiance)

            this.submit_details()
            
        }
    }


}

</script>


<style scoped>
.errorclass{
    border-bottom: solid 1px red;
}

.slider-image{
    border: solid 4px #000;
}

</style>