import Vuex from 'vuex'


export default new Vuex.Store({
  state: {
    api_url: 'https://appserver.emiinfluencersapp.com/',
    sample : '',
    logos : '',
    qr_code : '',
    website_images : '',
    website_text : ''
    
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})