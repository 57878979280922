import {createRouter,createWebHistory} from 'vue-router'


import Purpose from '../components/Purpose'
import Feel from '../components/Feel'
import Images from '../components/Images'
import Success from '../components/Success'
import Welcome from '../components/Welcome'
import Terms from '../components/Terms'
import SignatureHome from '../components/SignatureHome'
import SignatureDetails from '../components/SignatureDetails'
import LogoTerms from '../components/LogoTerms'
import WebDesign from '../components/WebDesign'
import WebsiteTechnicalDetails from '../components/WebsiteTechnicalDetails'



const routes = [

{
path: '/',
name : 'home',
component : Welcome
},

{
path: '/purpose',
name : 'purpose',
component : Purpose
},

{
path: '/feel',
name : 'feel',
component : Feel
},

{
path: '/images',
name : 'images',
component : Images
},

{
path: '/success',
name : 'success',
component : Success
},

{
path: '/terms',
name : 'terms',
component : Terms
},


{
path: '/signature-home',
name : 'sigbaturehome',
component : SignatureHome
},

{
    path: '/signature-details',
    name : 'sigbaturedetails',
    component : SignatureDetails
    },

{
    path: '/logo-terms',
    name : 'logo-terms',
    component : LogoTerms
    },

    {
    path: '/web-design',
    name : 'web-design',
    component : WebDesign
    },

   {
    path: '/website-technical-details',
    name : 'website-technical-details',
    component : WebsiteTechnicalDetails
    },


     
]


const router = createRouter({
    history : createWebHistory(process.env.BASE_URL),
    routes: routes
})


export default router


