<template>

    <Header />
     
    <section style="padding-top: 30px;">
        <div class="container">
            <h3>Terms & Conditions</h3>

<p>Thank you for selecting our graphic design services. Your agreement to our terms and conditions is implicit when you proceed with the project. Please find outlined below our policy regarding revisions, redesigns, and refunds:
    </p>

<h4 style="color: #391847;">Revision Limit</h4>
<p>Our service includes three (3) revisions per design task or project. These revisions are incorporated in the initial quotation provided to you.
</p>

<h4 style="color: #391847;">Revision Definition</h4>
<p>
A 'revision' is understood as minor adjustments to the current design, which might include color changes, font changes, layout modifications, or design element alterations. This does not apply to a total redesign.
</p>

<h4 style="color: #391847;">Redesign Policy</h4>
<p>
In instances where the client does not approve of the initial design, we will provide one extra redesign following a detailed meeting to understand client expectations. This is applicable unless the client is under a monthly contract, in which case different conditions may apply.
</p>

<h4 style="color: #391847;">Additional Revisions</h4>
<p>
Any revisions beyond the initial three or the extra redesign, if applicable, will incur additional charges.
</p>

<h4 style="color: #391847;">Refund Policy</h4>
<p>
Please be advised that no refunds will be issued if the client does not approve of any of the designs created during the design process.
</p>


<h4 style="color: #391847;">Requesting Revisions</h4>
<p>
All revision requests must be made within the agreed-upon timeframe. It is crucial for clients to provide detailed and specific feedback during revision requests to ensure we can meet your expectations effectively.
</p>

<p style="font-weight: 700;">
By agreeing to these terms, you acknowledge and accept our design, revision, and refund policies. We are dedicated to delivering a design that matches your vision and meets your needs.
</p>

        </div>
    </section>
    
    <Footer />
</template>


<script>
import Header from './layouts/Header'
import Footer from './layouts/Footer'

export default {
    components : {Header,Footer},
    
        data () {
            return {}
        },
    
        methods : {
    
        },
    
        created (){},
        
    }
    
    </script>
    
    