<template>
    <footer style="background-color: rgba(0, 0, 0, 0.9); padding-top: 50px; padding: 30px;  margin-top: 0px; left: 0; bottom: 0; width: 100%; ">

<div class="container">

    <div style="text-align: center; padding-top: 30px; padding-bottom: 50px;">
        <img src="assets/images/EMI-LOGO-1-1536x630.png" style="height:50px;">
    </div>

    <div class="row">
        <div class="col-md-12" style="text-align: center;">
            <router-link to="" style="color: #fbcf56; text-decoration: none; margin-right: 10px;">Home</router-link>
            <router-link to="" style="color: #fbcf56; text-decoration: none; margin-right: 10px;">Terms & Conditions</router-link>
            <router-link to="" style="color: #fbcf56; text-decoration: none;">Main Website</router-link>
            <p style="color:grey;">Copyright © 2024 EMI Influencers</p>
        </div>
       
    </div>
</div>

</footer>
</template>