<template>

<Header />
   
<section style="background-color: #391847;">

    <div class="container">

        <div class="row">
         

            <div class="col-md-12" style="padding-top: 20px; padding-bottom: 20px; text-align: center;">

               <h1 style="color: #fbcf56 ;">
                <i class="bi bi-check-circle"></i>
               </h1>
              
                <h1 style="color:lightgrey;">Success!</h1>
                <p style="color:lightgrey;">Your order has been submitted successfully</p>

             


                
                <div style="padding-top: 20px;">
                    <a href="https://app.emiinfluencersapp.com/" class="btn custom-btn">New Design</a>
                </div>

            </div>

        

        </div>
        
    </div>
    </section>
    <Footer />
</template>


<script>
import Header from './layouts/Header'
import Footer from './layouts/Footer'

export default {
    components : {Header,Footer},

    data () {
        return {
            company_name : localStorage.getItem('company_name'),
            tag_line : localStorage.getItem('tag_line'),
            purpose : localStorage.getItem('purpose'),
            feels : localStorage.getItem('feels'),
            images : localStorage.getItem('images'),
            show_loader : true,
            show_designer : true

        }
    },

    methods : {

    },

    created (){


    },
    
}

</script>

