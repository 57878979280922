import { createApp } from 'vue'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'

import bootstrap from  'bootstrap/dist/js/bootstrap.js'
import '../public/assets/css/style.css'
import '../public/assets/js/app.js'

import router from './routes'
import state from './store'
import 'animate.css'




createApp(App)
.use(router)
.use(state)
.use(bootstrap)
.mount('#app')
