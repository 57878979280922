<template>

    <div :class=" { 'blur' : show_payment_modal } ">


    <Header />

    <section style="background-color: #391847; padding-bottom: 100px;">

        <div class="container">

            <div class="row">

                <div class="col-md-12" style="text-align: center; padding-top: 50px;">
                    <h4 style="color: lightgrey">Order for logo</h4>
                    <h1 style="color: #fbcf56;">Decide how your logo would look like</h1>   
                </div>

                <div class="col-md-1"></div>


                <div class="col-md-10">

            <form @submit.prevent="get_details()">

                <div class="row">
                    <div class="col-md-6">
                        <input type="text" class="form-control input-design" placeholder="Logo/Brand name as it should appear on the design" v-model="title">
                    </div>

                    <div class="col-md-6">
                        <input type="text" class="form-control input-design" placeholder="Add your tagline (Optional)" v-model="tagline">
                    </div>

                    <div v-if="industry!='Other(Please specify)'" class="col-md-12" style="text-align: center; padding-top: 20px;">
                        <label style="color: lightgrey; padding-bottom: 10px;">What's your industry</label>
                        
                        <select class="form-select input-design" v-model="industry">
                            <option selected>Advertising, Arts and Media</option>
                            <option>Beauty & Wellness</option>
                            <option>Consulting and coaching</option>
                            <option>Healthcare and medical</option>
                            <option>Legal</option>
                            <option>Photography</option>
                            <option>Real estate and property</option>
                            <option>Other(Please specify)</option>
                        </select>

                    </div>

                    <div class="col-md-12" v-if="industry=='Other(Please specify)'">
                       
                        <input v-model="industry2" type="text" class="form-control input-design" placeholder="Please specify">
                  
                    </div>


                </div>

                <div style="text-align: center; padding-top: 20px; ">
    <router-link to="/signature-home" class="btn custom-btn" style="margin-right: 15px;"><i class="bi bi-arrow-left"></i> Previous</router-link>
    <button class="btn custom-btn">  Proceed <i class="bi bi-arrow-right"></i></button>
</div>

            </form>


                





                </div>

                <div class="col-md-1"></div>

            </div>

        </div>

    </section>




    <div class="moda shadow-lg" v-if="show_modal">

       <div class="moda-content">
        <form @submit.prevent="contact_information()" enctype="multipart/form-data">

<div class="row">
    <div class="col-md-10 col-10">
        <h4 style="color:#391847;"> Lets get more information about your brand</h4>
    </div>
    <div class="col-md-2 col-2" style="text-align:right;">
        <button  class="btn text-danger" @click="show_modal=false"> <i class="bi bi-x-circle"></i> </button> 
    </div>
</div>

<div class="row">

  <div class="col-md-6">
    <label>Background information</label>
        <input type="text" placeholder="Background and short description of the brand" class="form-control input-design" v-model="description">
    </div>

     <div class="col-md-6">
        <label>Design deadline</label>
        <input type="date" placeholder="Design deadline" class="form-control input-design" v-model="deadline">
    </div>


       <div class="col-md-6">
        <input type="text" placeholder="Do you have specific images or icons that you wish  be in your logo?" class="form-control input-design" v-model="icons">
    </div>


      <div class="col-md-6">
        <input type="text" placeholder="Do you have any color preferences or any existing colors you want in your logo?" class="form-control input-design" v-model="colors">
    </div>


      <div class="col-md-6">
        <input type="text" placeholder="Who are your main competitors? Please list their websites if possible." class="form-control input-design" v-model="competitors">
    </div>


      <div class="col-md-6">
        <input type="text" placeholder="What are your thoughts about their logos? How do you want your logo to differ from their logos?" class="form-control input-design" v-model="thoughts">
    </div>


       <div class="col-md-12">
        <input type="text" placeholder="Please supply a brief description of the design you have in mind and any other information that would be useful." class="form-control input-design" v-model="comments">
    </div>



       <div class="col-md-12">
        <label>Upload sample design(optional)</label>
        <input type="file" placeholder="If you already have a design concept you’d like to be used, Kindly share" class="form-control input-design" @change="get_sample_design">
    </div>


        <div class="col-md-12">
        <input type="text" placeholder="What is the most important application for your logo? (i.e. Stationery, Signage, T-shirt, etc.)" v-model="application" class="form-control input-design">
    </div>


    <div class="col-md-12" style="padding-top:20px;">
        <h4 style="color:#391847;"> Contact information</h4>
    </div>



    <div class="col-md-6">
        <input type="text" placeholder="First name" class="form-control input-design" v-model="first_name">
    </div>

    <div class="col-md-6">
        <input type="text" placeholder="Last name" class="form-control input-design" v-model="last_name">
    </div>

    <div class="col-md-6">
        <input type="email" placeholder="Email" class="form-control input-design" v-model="email">
    </div>

    <div class="col-md-6">
        <input type="text" placeholder="Phone" class="form-control input-design" v-model="phone">
    </div>

    <div class="col-md-6" style="padding-top: 20px;">
        <button type="button" class="btn custom-btn-3" style="margin-right: 10px;" @click="show_modal=false"> <i class="bi bi-x-circle"></i> Close</button>
        <button type="submit" :disabled="disabled" class="btn custom-btn-3">{{text}} <i class="bi bi-arrow-right"></i> </button>
    </div>


</div>

</form>
       </div>

    </div>
<Footer />


        
    </div>




<!-- payment modal -->
<div class="my-modal m-2" v-if="show_payment_modal">
    
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6" style="padding-top:100px;">
            
            <div style="background-color:#fff;" class="shadow-lg rounded p-3">
                <h6><b>Pay now and have your flyer designed quicker</b></h6>

                <div style="padding-top:10px;">
                    <h5>How to pay via <b>e-transfer</b> </h5>
                </div>

                <div>
                    <p> <i class="bi bi-check color"></i>For personal logo pay 500CAD to <b>Eminentmediainc@gmail.com</b></p>
                    <p> <i class="bi bi-check color"></i>For business logo pay 1000CAD to <b>Eminentmediainc@gmail.com</b></p>
                     <p> <i class="bi bi-check color"></i> After paying upload screenshot of <b>payment receipt</b> here</p>
                </div>

                <div>
                    
                    <form @submit.prevent="submit_receipt()" enctype="multipart/form-data">
                        <input type="file" required class="form-control input-design" @change="get_receipt_file">
                        <div style="padding-top:20px;">
                            <div class="row">
                                <div class="col-md-6 col-6">
                                    <button class="btn custom-btn-3" :disabled="disabled2">{{text2}}</button>
                                </div>
                                <div class="col-md-6 col-6" style="text-align:right;">
                                    <router-link to="/success" type="button" class="btn color"> <i class="bi bi-x-circle"></i> I will pay later</router-link>
                                </div>

                                <div style="padding-top:20px;">
                                <h5>Pay <b>online</b> </h5>
                                <a href="https://buy.stripe.com/eVa5kr1nwerE8g0cMO" class="btn custom-btn" style="margin-right:10px;">Personal logo</a>
                                <a href="https://buy.stripe.com/3cs7sz5DM2IWcwg003" class="btn custom-btn">Business logo</a>
                                </div>


                            </div>
                        </div>
                    </form>

                </div>

            </div>

        </div>

         <div class="col-md-3"></div>
    </div>

</div>



</template>





<script>
import Header from './layouts/Header'
import Footer from './layouts/Footer'
import axios from 'axios'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
    name : 'signaturehome',
    components : {Header,Footer},

    data () {
        return {
            show_modal : false,
            title : '',
            tagline : '',
            industry : '',
            first_name : '',
            last_name : '',
            email : '',
            phone : '',
            industry2 : '',
            text : 'Complete',
            text2 : 'Submit receipt',
            disabled2 : false,
            show_payment_modal : false,
            receipt : '',
            description : '',
            deadline : '',
            icons : '',
            colors : '',
            competitors : '',
            thoughts : '',
            comments : '',
            sample_design : '',
            application : '',

        }
    },

    methods : {

        async submit_receipt(){
            this.text2 = 'Please wait...'
            this.disabled2 = true
             const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
            const res =  await axios.post(this.$store.state.api_url+'api/submit-receipt',{
                receipt : this.receipt
            },config).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.text2 = 'Submit receipt'
            this.disabled2 = false
            this.$router.push('/success')

        },



         get_receipt_file(e){
            this.receipt = e.target.files[0]
             
        },

         get_sample_design(e){
            this.sample_design = e.target.files[0]
             
        },


        get_details(){
            this.show_modal = true
        },

        async contact_information(){
            var industry = ''
            if(this.industry=='Other(Please specify)'){
                industry = this.industry2
            }else{
                industry = this.industry
            }

            if(!this.title){
                  toast.error("Please specify title", {
        autoClose: 1000,
        theme : 'dark',
        position : 'top-center'
      });
            return
            }


              if(!this.tagline){
                  toast.error("Please specify tagline", {
        autoClose: 1000,
        theme : 'dark',
        position : 'top-center'
      });
            return
            }


              if(!industry){
                  toast.error("Please select industry", {
        autoClose: 1000,
        theme : 'dark',
        position : 'top-center'
      });
            return
            }

              if(!this.first_name){
                  toast.error("Enter first name", {
        autoClose: 1000,
        theme : 'dark',
        position : 'top-center'
      });
            return
            }


              if(!this.last_name){
                  toast.error("Enter last name", {
        autoClose: 1000,
        theme : 'dark',
        position : 'top-center'
      });
            return
            }


              if(!this.email){
                  toast.error("Enter email", {
        autoClose: 1000,
        theme : 'dark',
        position : 'top-center'
      });
            return
            }


              if(!this.phone){
                  toast.error("Enter phone", {
        autoClose: 1000,
        theme : 'dark',
        position : 'top-center'
      });
            return
            }

            this.text = 'Please wait...'
            this.disabled = true

             const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
            
            const res = await axios.post(this.$store.state.api_url+'api/submit-logo-details',{
                title : this.title,
                tagline : this.tagline,
                industry : industry,
                first_name : this.first_name,
                last_name : this.last_name,
                email : this.email,
                phone : this.phone,
                images : localStorage.getItem('images'),
                logo_type : localStorage.getItem('logo_type'),
                description : this.description,
                deadline : this.deadline,
                icons : this.icons,
                colors : this.colors,
                competitors : this.competitors,
                thoughts : this.thoughts,
                sample_design : this.sample_design,
                application : this.application,
                comments : this.comments
            },config).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.show_modal = false
            this.text = 'Order sent!'
            this.disabled = false
            this.show_payment_modal = true

        }
    }

}

</script>



<style scoped>
.signature{
    height: 150px;
    border-radius: 5px;
    border: solid 2px lightgrey;
}

.signature:hover{
    border: solid 2px #b57e10;   
}
</style>