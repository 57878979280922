<template>

<Header />
    
    <section style="background-color: #391847 ; padding-bottom: 40px;">
    <div class="container">

        <div class="row">

            <div class="col-md-12" style="padding-top: 100px; padding-bottom: 20px; text-align: left;">
                <h1 style="color: #fbcf56;">Which of these flyers do you like better?</h1>
                <p style="color:lightgrey;">Design is important.Help us to get to know your style</p>
            </div>

            <div class="col-md-12">
                
                <div class="row">

                    <div class="col-md-3 col-6">
                        <button :class="{errorstyle:show_error, 'image-border' : images.includes('Artboard 1.jpg') }" class="btn image-btn" @click="get_image('Artboard 1.jpg')">
                            <img src="assets/images/Artboard 1.jpg" class="image">
                        </button>
                    </div>


                    <div class="col-md-3 col-6">
                        <button :class="{errorstyle:show_error, 'image-border' : images.includes('Artboard 2 copy 2.jpg') }" class="btn image-btn" @click="get_image('Artboard 2 copy 2.jpg')">
                            <img src="assets/images/Artboard 2 copy 2.jpg" class="image">
                        </button>
                    </div>


                    <div class="col-md-3 col-6">
                        <button :class="{errorstyle:show_error, 'image-border' : images.includes('Artboard 2 copy.jpg') }" class="btn image-btn" @click="get_image('Artboard 2 copy.jpg')">
                            <img src="assets/images/Artboard 2 copy.jpg" class="image">
                        </button>
                    </div>


                    <div class="col-md-3 col-6">
                        <button :class="{errorstyle:show_error, 'image-border' : images.includes('Artboard 2 copy 5.jpg')}" class="btn image-btn" @click="get_image('Artboard 2 copy 5.jpg')">
                            <img src="assets/images/Artboard 2 copy 5.jpg" class="image">
                        </button>
                    </div>

                    <div class="col-md-3 col-6">
                        <button :class="{errorstyle:show_error, 'image-border' : images.includes('Artboard 2 copy 6.jpg')}" class="btn image-btn" @click="get_image('Artboard 2 copy 6.jpg')">
                            <img src="assets/images/Artboard 2 copy 6.jpg" class="image">
                        </button>
                    </div>

                    <div class="col-md-3 col-6">
                        <button :class="{errorstyle:show_error, 'image-border' : images.includes('Artboard 2 copy 7.jpg')}" class="btn image-btn" @click="get_image('Artboard 2 copy 7.jpg')">
                            <img src="assets/images/Artboard 2 copy 7.jpg" class="image">
                        </button>
                    </div>

                    <div class="col-md-3 col-6">
                        <button :class="{errorstyle:show_error, 'image-border' : images.includes('Artboard 2 copy 9.jpg')}" class="btn image-btn" @click="get_image('Artboard 2 copy 9.jpg')">
                            <img src="assets/images/Artboard 2 copy 9.jpg" class="image">
                        </button>
                    </div>

                    <div class="col-md-3 col-6">
                        <button :class="{errorstyle:show_error, 'image-border' : images.includes('Artboard 2 copy 8.jpg')}" class="btn image-btn" @click="get_image('Artboard 2 copy 8.jpg')">
                            <img src="assets/images/Artboard 2 copy 8.jpg" class="image">
                        </button>
                    </div>


                </div>

                


                    <div style="padding-top: 20px;">
                        <router-link to="/feel" style="margin-right:10px;" class="btn custom-btn" >Back</router-link>
                        <button @click="next_stage()" class="btn custom-btn">Next</button>
                    </div>
               
            </div>

         

        </div>
        
    </div>
    </section>
    <Footer />
</template>


<script>
import Header from './layouts/Header'
import Footer from './layouts/Footer'
import axios from 'axios'

export default {
    components : {Header,Footer},
    created (){},

    data () {
        return {
            images : [],
            show_error : false,
            s: ''
        }
    },

    methods : {

        get_image (image){
            this.images = []
            if(this.images.includes(image)){
                this.images.pop(image)
            }else{
                        this.images = this.images.concat(image)
            localStorage.setItem('images',this.images)
            this.show_error=false    
            }


            
        },

        next_stage(){
            if(this.images.length===0){
                this.show_error=true
                
                return
            }else{
                this.submit_details()
                this.$router.push('/success')
            }
        },

        async submit_details(){
             //uploa a file
             const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
                //uploa a file
                //console.log(this.$store.state.sample)
            const res = await axios.post(this.$store.state.api_url+'api/submit-flyer-details',{
                company_name : localStorage.getItem('company_name'),
                purpose : localStorage.getItem('purpose'),
                audiance : localStorage.getItem('audiance'),
                company_name : localStorage.getItem('company_name'),
                event_title : localStorage.getItem('event_title'),
                event_time : localStorage.getItem('event_time'),
                event_location : localStorage.getItem('event_location'),
                event_contacts : localStorage.getItem('event_contacts'),
                deadline : localStorage.getItem('deadline'),
                name : localStorage.getItem('name'),
                email : localStorage.getItem('email'),
                phone : localStorage.getItem('phone'),
                images : localStorage.getItem('images'),
                event_date : localStorage.getItem('event_date'),

                flyer_size : localStorage.getItem('flyer_size'),
                flyer_type : localStorage.getItem('flyer_type'),
                guidelines : localStorage.getItem('guidelines'),
                theme : localStorage.getItem('theme'),
                image_sources : localStorage.getItem('image_sources'),
                copyright : localStorage.getItem('copyright'),
                sides : localStorage.getItem('sides'),
                competitors : localStorage.getItem('competitors'),
                logos : this.$store.state.logos,
                comments : localStorage.getItem('comments'),
                sample : this.$store.state.sample,
                qr_code : this.$store.state.qr_code,
               
                

            },config).then(function(response){
                console.log(response.data)
            }).catch(function(error){
                console.log(error)
            })
           
        }
    }


}

</script>


<style scoped>
.image{
    width: 100%;
    border-radius: 10px;
}

.errorstyle{
    border: solid 1px red;
}

.image-btn:hover{
    border: solid 2px #fbcf56 ;
}

.image-border{
    border: solid 2px #800;
}
</style>